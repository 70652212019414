/*
 * SonarQube
 * Copyright (C) 2009-2021 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.web-api-page-header {
  margin: 10px 20px;
}

.web-api-search {
  margin: 20px 10px 0;
  padding: 0 10px 20px;
  border-bottom: 1px solid #e6e6e6;
  white-space: nowrap;
}

.web-api-domain-header,
.web-api-action-header {
  display: flex;
  align-items: center;
}

.web-api-domain-title {
  font-size: 18px;
  font-weight: 400;
}

.web-api-domain-description {
  margin-top: 10px;
  line-height: 1.5;
}

.web-api-domain-actions {
  margin-top: 16px;
}

.web-api-action-title {
  font-weight: 600;
}

.web-api-action-description {
  margin-top: 10px;
}

.web-api-action-actions {
  margin-top: 10px;
}

.web-api-action hr {
  margin: 10px 0 0;
  background-color: #ddd;
}

.web-api-params,
.web-api-response {
  margin-top: 10px;
}

.web-api-params > table {
  width: 100%;
  table-layout: fixed;
}

.web-api-params td {
  vertical-align: top;
  padding: 8px 10px;
  border-top: 1px solid #e6e6e6;
}

.web-api-params tr:first-child td {
  border-top: none;
}

.web-api-params td code {
  white-space: normal;
  word-wrap: break-word;
}

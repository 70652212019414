/*
 * SonarQube
 * Copyright (C) 2009-2021 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
[class^='icon-'],
[class*=' icon-'] {
  line-height: 1;
  vertical-align: middle;
}

a[class^='icon-'],
a[class*=' icon-'] {
  border-bottom: none;
}

.icon-half-transparent {
  opacity: 0.5;
}

/*
 * Colors
 */
.icon-gray {
  color: #999;
}

.icon-gray path {
  fill: #999;
}

.icon-color-link {
  color: #236a97;
}

/*
 * Common
 */
.icon-outline {
  transition: all 0.2s ease !important;
}

.icon-outline path {
  stroke: #666;
  stroke-width: 1.41421356;
  stroke-opacity: 1;
  fill-opacity: 0;
  vector-effect: non-scaling-stroke;
  transition: all 0.2s ease;
}

.icon-outline.is-filled path {
  fill: currentColor;
  stroke: currentColor;
  fill-opacity: 1;
}

/*
 * SonarQube
 * Copyright (C) 2009-2021 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.panel {
  padding: 10px;
}

.panel:not(:last-child) {
  border-bottom: 1px solid #e6e6e6;
}

.panel-vertical {
  padding-left: 0;
  padding-right: 0;
}

.panel-white {
  border: 1px solid #e6e6e6;
  background-color: #fff;
}

.panel-warning {
  border: 1px solid #faebcc;
  background-color: #fcf8e3;
  color: #8a6d3b;
}

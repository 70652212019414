/*
 * SonarQube
 * Copyright (C) 2009-2021 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.level {
  display: inline-block;
  width: auto;
  min-width: 80px;
  padding-left: 9px;
  padding-right: 9px;
  height: 24px;
  line-height: 24px;
  border-radius: 24px;
  box-sizing: border-box;
  color: #fff;
  letter-spacing: 0.02em;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.35);
}

.level-small {
  width: auto;
  min-width: 64px;
  padding-left: 9px;
  padding-right: 9px;
  margin-top: -1px;
  margin-bottom: -1px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
}

.level-muted {
  background-color: #bdbdbd !important;
}

a > .level {
  margin-bottom: -1px;
  border-bottom: 1px solid;
  transition: all 0.2s ease;
}

a > .level:hover {
  opacity: 0.8;
}

.level-OK {
  background-color: #00aa00;
}

.level-WARN {
  background-color: #ed7d20;
}

.level-ERROR {
  background-color: #d4333f;
}

.level-NONE {
  background-color: #b4b4b4;
}

.level-NOT_COMPUTED {
  background-color: #404040;
}

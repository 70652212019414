/*
 * SonarQube
 * Copyright (C) 2009-2021 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.deferred-spinner {
  position: relative;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  border: 2px solid #4b9fd5;
  border-radius: 50%;
  animation: spin 0.75s infinite linear;
}

.deferred-spinner:before,
.deferred-spinner:after {
  left: -2px;
  top: -2px;
  display: none;
  position: absolute;
  content: '';
  width: inherit;
  height: inherit;
  border: inherit;
  border-radius: inherit;
}

.deferred-spinner,
.deferred-spinner:before,
.deferred-spinner:after {
  display: inline-block;
  box-sizing: border-box;
  border-color: transparent;
  border-top-color: #4b9fd5;
  animation-duration: 1.2s;
}

.deferred-spinner:before {
  transform: rotate(120deg);
}

.deferred-spinner:after {
  transform: rotate(240deg);
}

.deferred-spinner-placeholder {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  visibility: hidden;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*
 * SonarQube
 * Copyright (C) 2009-2021 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.projects-page .layout-page-header-panel-inner,
.projects-page .layout-page-header-panel {
  height: 98px;
  line-height: normal;
}

.projects-topbar-item + .projects-topbar-item {
  padding-left: 24px;
}

.projects-topbar-item .spinner {
  top: -1px;
}

.projects-topbar-item.is-last {
  margin-left: auto;
  padding-left: 32px;
}

.projects-topbar-item.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.projects-topbar-item.disabled * {
  pointer-events: none !important;
}

.projects-topbar-item-search {
  position: relative;
  flex: 1;
  height: 24px;
}

.projects-list .page-actions {
  margin-bottom: 0;
}

.project-card-name {
  font-weight: 600;
}

.projects-leak-sorting-option.is-focused {
  background-color: #f1e8cb;
}

.projects-facet-list {
  padding-left: 10px;
  padding-right: 10px;
}

.projects-facets-header {
  margin-bottom: 10px;
  padding: 10px 0;
  border-bottom: 1px solid #e6e6e6;
}

.projects-facets-reset {
  float: right;
}

.projects-facet-bar {
  display: inline-block;
  width: 60px;
  margin-left: 8px;
}

.projects-facet-bar-inner {
  min-width: 1px;
  height: 10px;
  background-color: #b4b4b4;
  transition: width 0.3s ease;
}

.search-navigator-facet.active .projects-facet-bar-inner,
.search-navigator-facet-highlight-under-container
  .search-navigator-facet.active
  ~ .search-navigator-facet
  .projects-facet-bar-inner {
  background-color: #4b9fd5;
}

.projects-visualization {
  position: relative;
  height: 600px;
}

.projects-visualization .measure-details-bubble-chart-axis.y {
  width: 300px;
  left: 15px;
  margin-top: 150px;
  transform-origin: 0 0;
  text-align: center;
}

.projects-visualizations-footer {
  margin-top: 8px;
  padding: 15px 60px;
  border-top: 1px solid #e6e6e6;
  font-size: 12px;
  line-height: 1.4;
  text-align: center;
}

.projects-visualizations-footer .note {
  font-style: italic;
}

.measure-details-bubble-chart-title {
  position: absolute;
  left: 20px;
}

.measure-details-bubble-chart-axis {
  position: absolute;
  color: #666;
  font-size: 12px;
}

.measure-details-bubble-chart-axis.x {
  left: 50%;
  bottom: 16px;
  width: 500px;
  margin-left: -250px;
  text-align: center;
}

.measure-details-bubble-chart-axis.y {
  top: 50%;
  left: -20px;
  transform: rotate(-90deg);
}

.measure-details-bubble-chart-axis.size {
  display: flex;
  align-items: center;
  justify-content: space-around;
  top: 16px;
  width: 100%;
}

.projects-empty-list {
  padding: 32px 0;
  text-align: center;
}

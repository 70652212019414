/*
 * SonarQube
 * Copyright (C) 2009-2021 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.location-message {
  display: inline-block;
  vertical-align: top;
  line-height: 16px;
  padding: 0 6px;
  border-radius: 2px;
  background-color: #9e9e9e;
  color: #fff;
  font-family: 'Helvetica Neue'
,
Helvetica
,
Arial
,
sans-serif;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: background-color 0.3s ease;
}

.location-message.selected {
  background-color: #475760;
}

.location-index + .location-message {
  margin-left: 4px;
}

.location-index > .location-message {
  display: none;
  position: absolute;
  bottom: calc(100% + 4px);
  left: 0;
}

.location-index:hover > .location-message {
  display: block;
}

.location-index > .location-message::after {
  position: absolute;
  bottom: -5px;
  left: 4px;
  width: 0;
  height: 0;
  border-top: 5px solid #475760;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  content: '';
}

.source-line-code .location-message {
  padding-top: 2px;
  padding-bottom: 2px;
}

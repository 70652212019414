/*
 * SonarQube
 * Copyright (C) 2009-2021 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.code-snippet {
  background: #e6e6e6;
  border-radius: 3px;
}

.code-snippet pre {
  padding: 8px 16px;
  border-right: 1px solid rgba(200, 200, 200, 0.5);
  overflow-y: hidden;
  overflow-x: auto;
}

.code-snippet > button {
  height: auto;
  border: 0;
  border-radius: 0;
  background: transparent;
  padding: 8px;
}

.code-snippet > button:hover,
.code-snippet > button:focus,
.code-snippet > button:active {
  background-color: rgba(200, 200, 200, 0.5);
  color: #236a97;
}

/*
 * SonarQube
 * Copyright (C) 2009-2021 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.markdown-content .alert {
  margin-bottom: 8px;
  border: 1px solid;
  border-radius: 2px;
}

.markdown-content .alert.is-inline {
  display: inline-flex;
}

.markdown-content .alert:empty {
  display: none;
}

.markdown-content .alert-error,
.markdown-content .alert-danger {
  border-color: #f4b1b0;
  background-color: #f2dede;
  color: #862422;
}

.markdown-content .alert-error .alert-icon,
.markdown-content .alert-danger .alert-icon {
  border-color: #f4b1b0;
}

.markdown-content .alert-warning {
  border-color: #faebcc;
  background-color: #fcf8e3;
  color: #6f4f17;
}

.markdown-content .alert-warning .alert-icon {
  border-color: #faebcc;
}

.markdown-content .alert-info {
  border-color: #b1dff3;
  background-color: #d9edf7;
  color: #0e516f;
}

.markdown-content .alert-info .alert-icon {
  border-color: #b1dff3;
}

.markdown-content .alert-success {
  border-color: #d6e9c6;
  background-color: #dff0d8;
  color: #215821;
}

.markdown-content .alert-success .alert-icon {
  border-color: #d6e9c6;
}

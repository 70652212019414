/*
 * SonarQube
 * Copyright (C) 2009-2021 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.navbar-context,
.navbar-context .navbar-inner {
  background-color: #fff;
  z-index: 420;
}

.navbar-context .navbar-inner {
  padding-top: 8px;
  border-bottom: 1px solid #e6e6e6;
}

.navbar-context .navbar-inner-with-notif {
  border-bottom: none;
}

.navbar-context-justified {
  display: flex;
  justify-content: space-between;
}

/* use `min-width: 0` to cut breadcrumb links (to end with "...") */
/* https://stackoverflow.com/questions/38223879/white-space-nowrap-breaks-flexbox-layout */
.navbar-context-header {
  display: flex;
  align-items: center;
  min-width: 0;
  height: 32px;
  font-size: 16px;
}

/* disallow icons and slash separators to shrink */
.navbar-context-header > *:not(.navbar-context-header-breadcrumb-link) {
  flex-shrink: 0;
}

.navbar-context-header-breadcrumb-link {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.navbar-context-header .slash-separator {
  margin-left: 8px;
  margin-right: 8px;
  font-size: 24px;
}

.navbar-context-header .slash-separator::after {
  color: rgba(68, 68, 68, 0.2);
}

/* set `min-width: 0` to allow flexbox item to shrink */
/* https://stackoverflow.com/questions/38223879/white-space-nowrap-breaks-flexbox-layout */
.navbar-context-meta {
  display: flex;
  align-items: center;
  height: 32px;
  padding-left: 20px;
  color: #666;
  font-size: 12px;
  text-align: right;
}

.navbar-context-meta-secondary {
  position: absolute;
  top: 34px;
  right: 0;
  padding: 0 20px;
  white-space: nowrap;
}

.navbar-context-description {
  display: inline-block;
  line-height: 24px;
  margin-left: 8px;
  padding-top: 4px;
  color: #666;
  font-size: 12px;
}
